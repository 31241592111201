<template>
  <MainLayout title="Menu">
    <MarketingPoster />
    <div class="flex min-h-screen flex-col">
      <div
        ref="headerNavigationContainer"
        class="sticky top-0 left-0 right-0 z-30 overflow-x-hidden bg-theme-background"
        style="padding-top: var(--safe-area-inset-top)"
      >
        <div
          class="-mx-1 flex justify-between px-4 pt-4 pb-2 [&>*]:mx-1"
          style="height: calc(4.25rem + 1px)"
        >
          <ADeepCircleButton
            v-if="isMenuSelectionButtonVisible"
            @click="$router.push({ name: 'menuSelection' })"
          >
            <template #icon><ArrowLeftBold /></template>
          </ADeepCircleButton>
          <div class="flex flex-1 justify-end">
            <div
              class="relative h-10 min-w-0 overflow-hidden rounded-full bg-theme-background transition-[width]"
              :class="[searchExpanded ? 'w-full' : 'w-10']"
            >
              <input
                ref="search"
                v-model="search"
                type="text"
                :placeholder="$t('Search')"
                class="block h-full w-full rounded-full border-none bg-theme-primary/10 pr-8 pl-9 text-sm placeholder-theme-text/50 transition focus:ring-0"
                @focus="searchFocused = true"
                @blur="searchFocused = false"
              />
              <div
                role="button"
                tabindex="0"
                class="absolute top-0 bottom-0 flex h-10 w-10 items-center justify-center rounded-full transition duration-75 ltr:left-0 rtl:right-0"
                :class="searchExpanded ? '' : 'bg-theme-primary'"
                @click="$refs.search.focus()"
              >
                <MagnifyingGlassBold
                  class="w-5 transition"
                  :class="[
                    searchExpanded
                      ? 'text-theme-primary'
                      : 'text-theme-button-text',
                  ]"
                />
              </div>
              <div
                v-if="searchExpanded"
                class="absolute top-0 bottom-0 flex h-full cursor-pointer items-center ltr:right-0 rtl:left-0"
                @click="
                  () => {
                    search = '';
                    $refs.search.blur();
                  }
                "
              >
                <XCircleBold class="mx-3 w-5 text-theme-primary" />
              </div>
            </div>
          </div>

          <template v-if="!searchExpanded">
            <ADeepCircleButton
              v-if="$store.getters.favoriteProducts.length > 0"
              :badge="$store.getters.favoriteProducts.length.toLocaleString()"
              @click="$router.push({ name: 'favorites' })"
            >
              <HeartBold class="h-5 w-5" />
            </ADeepCircleButton>
            <ADeepCircleButton
              v-if="
                $store.state.restaurant.forms.length > 0 &&
                $store.state.onLine &&
                !$store.state.isEmbedMode
              "
              @click="$router.push({ name: 'form' })"
            >
              <template #icon><ChatTextBold /></template>
            </ADeepCircleButton>
            <LanguagePickerCircleButton
              v-if="$store.getters.availableLanguages.length > 1"
            />
            <ADeepCircleButton
              v-if="!$store.state.isEmbedMode"
              @click="$router.push({ name: 'about' })"
            >
              <template #icon><InfoBold /></template>
            </ADeepCircleButton>
          </template>
        </div>
      </div>

      <div ref="bannerContainer" class="shrink-0 px-4">
        <MenuBanner
          v-if="banner"
          :banner="banner"
          :banner-video="bannerVideo"
        />
      </div>

      <div class="relative">
        <div
          ref="stickyBar"
          class="sticky z-40 bg-theme-background"
          style="top: calc(4.25rem + var(--safe-area-inset-top))"
        >
          <div
            class="absolute left-0 bottom-0 right-0 border-t border-theme-text/5"
          ></div>
          <div class="relative mt-3 flex h-12 items-center pb-3">
            <button
              type="button"
              class="relative flex h-full w-12 flex-shrink-0 items-center focus:outline-none"
              @click="categorySidebarExpanded = true"
            >
              <ListBold class="w-5 text-theme-primary ltr:ml-4 rtl:mr-4" />
            </button>

            <div
              ref="category-buttons"
              class="scrollbar-none relative flex h-full overflow-x-auto"
            >
              <button
                v-for="category in filteredCategories"
                :key="category.id"
                :ref="`category-button-${category.id}`"
                class="relative touch-manipulation whitespace-nowrap rounded-lg px-3 py-1 font-medium transition ltr:mr-2 rtl:ml-2"
                type="button"
                :class="[
                  category.id === focusedCategoryId
                    ? 'bg-theme-primary text-theme-button-text'
                    : 'bg-theme-text/[0.03] text-theme-text active:bg-theme-text/10',
                ]"
                @click="scrollToCategory(category.id)"
              >
                {{ $getTranslation(category.name) }}
              </button>
              <div class="ltr:pl-2 rtl:pr-2">&nbsp;</div>
            </div>
          </div>
        </div>
        <div class="relative flex">
          <CategorySidebar
            :categories="filteredCategories"
            :expanded="categorySidebarExpanded"
            :focused-category-id="focusedCategoryId"
            @hide="categorySidebarExpanded = false"
            @category-click="(category) => scrollToCategory(category.id)"
          />

          <div class="max-w-full flex-1">
            <div
              v-if="filteredCategories.length === 0"
              class="mt-6 px-4 text-center text-theme-text"
            >
              {{
                $t(
                  menu.categories.length > 0
                    ? 'Nothing found'
                    : 'This menu is empty',
                )
              }}.
            </div>
            <div>
              <AboutRestaurant
                v-if="['darabzeen', 'jixiangdimsum'].includes(restaurant.slug)"
                show-contact-number
                show-address
                show-instagram
                show-wifi
                class="px-4 pt-6"
              />
              <RecentOrders class="px-4 pt-6" />
              <div
                v-if="$getTranslation(menu.globalMenuNote)"
                class="break-words px-4 pt-6 [&_a]:underline"
                v-html="$getTranslation(menu.globalMenuNote)"
              />
              <section
                v-for="(category, categoryIndex) in filteredCategories"
                :key="category.id"
                :ref="`category-${category.id}`"
                class="px-4 pt-6 pb-4"
                :class="{
                  'min-h-[calc(100vh-210px)]':
                    categoryIndex === filteredCategories.length - 1,
                }"
              >
                <div class="mb-4">
                  <h2 class="break-words text-xl font-semibold">
                    {{ $getTranslation(category.name) }}
                  </h2>

                  <div
                    v-if="
                      $getTranslation(category.description) ||
                      $getTranslation(menu.globalCategoryNote)
                    "
                    class="mt-1 break-words font-secondary text-sm text-theme-text"
                  >
                    <div v-if="$getTranslation(category.description)">
                      <p
                        v-for="(
                          categoryDescriptionLine, categoryDescriptionLineIndex
                        ) in $getTranslation(category.description).split('\n')"
                        :key="categoryDescriptionLineIndex"
                      >
                        {{ categoryDescriptionLine }}
                      </p>
                    </div>
                    <div
                      v-if="$getTranslation(menu.globalCategoryNote)"
                      class="[&_a]:underline"
                      v-html="$getTranslation(menu.globalCategoryNote)"
                    ></div>
                  </div>
                </div>

                <ProductCards
                  :products="
                    category.products.filter(
                      (product) =>
                        isCategoryExplicitlyMatchedBySearch(category) ||
                        isProductExplicitlyMatchedBySearch(product),
                    )
                  "
                  :is-first="categoryIndex === 0"
                />
              </section>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1"></div>
      <div class="flex min-h-[60px] flex-col items-center justify-end">
        <RouterLink :to="{ name: 'about' }" class="text-sm hover:underline">
          {{ restaurant.name }}
        </RouterLink>
        <div
          v-if="!restaurant.hidePoweredBy"
          class="px-4 py-2 text-center font-secondary text-sm"
        >
          Menu created with
          <a
            :href="`https://apetitomenu.com?utm_source=apetitomenu&utm_medium=menufooter&utm_campaign=${restaurant.slug}`"
            target="_blank"
            class="font-semibold hover:underline"
            >Apetito Menu</a
          >
        </div>
      </div>
      <div class="sticky bottom-0 z-40 w-full">
        <div
          class="pointer-events-none flex flex-col transition-all"
          style="
            padding-bottom: var(--safe-area-inset-bottom);
            padding-left: var(--safe-area-inset-left);
            padding-right: var(--safe-area-inset-right);
          "
        >
          <div class="mb-4 flex justify-between px-4">
            <div></div>
            <div>
              <Transition
                enter-active-class="ease-out duration-100"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ADeepCircleButton
                  v-if="scrollToTopButtonVisible"
                  class="pointer-events-auto"
                  @click="scrollToTop"
                >
                  <template #icon><ArrowUpBold /></template>
                </ADeepCircleButton>
              </Transition>
            </div>
          </div>
        </div>
        <div
          v-if="
            $store.state.isOrderMode &&
            $store.getters.cartItemsTotalQuantity > 0
          "
          class="mb-4 px-4"
        >
          <RouterLink
            :to="{ name: 'cart' }"
            class="menu-button-primary !flex !items-center !p-3"
          >
            <div
              class="flex h-8 w-8 items-center justify-center rounded-full bg-white/10 font-semibold"
            >
              {{ $store.getters.cartItemsTotalQuantity }}
            </div>
            <div class="px-3 font-semibold">{{ $t('View cart') }}</div>
            <div class="flex-1"></div>
            <div
              v-if="$store.getters.cartItemsTotalPrice > 0"
              class="px-3 font-semibold"
            >
              {{ $formatPrice($store.getters.cartItemsTotalPrice) }}
            </div>
          </RouterLink>
        </div>
        <div
          v-if="menu && menu.isCategoryTypesNavbarEnabled"
          class="flex justify-center px-1"
        >
          <CategoryTypeNavBar />
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MainLayout from '@menu/Shared/MainLayout.vue';
import ProductCards from '@menu/Shared/ProductCards.vue';
import ADeepCircleButton from '@menu/Shared/ADeepCircleButton.vue';
import LanguagePickerCircleButton from '@menu/Shared/LanguagePickerCircleButton.vue';
import MarketingPoster from '@menu/Shared/MarketingPoster.vue';
import CategorySidebar from '@menu/Shared/CategorySidebar.vue';
import AboutRestaurant from '@menu/Shared/AboutRestaurant.vue';
import CategoryTypeNavBar from '@menu/Shared/CategoryTypeNavBar.vue';
import RecentOrders from '@menu/Shared/RecentOrders.vue';
import MenuBanner from '@menu/Shared/MenuBanner.vue';
import ArrowLeftBold from '@/phosphoricons/ArrowLeftBold.vue';
import ArrowUpBold from '@/phosphoricons/ArrowUpBold.vue';
import MagnifyingGlassBold from '@/phosphoricons/MagnifyingGlassBold.vue';
import XCircleBold from '@/phosphoricons/XCircleBold.vue';
import ListBold from '@/phosphoricons/ListBold.vue';
import InfoBold from '@/phosphoricons/InfoBold.vue';
import HeartBold from '@/phosphoricons/HeartBold.vue';
import ChatTextBold from '@/phosphoricons/ChatTextBold.vue';

export default {
  components: {
    ChatTextBold,
    HeartBold,
    InfoBold,
    ListBold,
    XCircleBold,
    MagnifyingGlassBold,
    ArrowUpBold,
    ArrowLeftBold,
    MenuBanner,
    RecentOrders,
    CategoryTypeNavBar,
    AboutRestaurant,
    CategorySidebar,
    MarketingPoster,
    LanguagePickerCircleButton,
    ADeepCircleButton,
    ProductCards,
    MainLayout,
  },
  data() {
    return {
      search: '',
      searchFocused: false,
      focusedCategoryId: null,
      windowScrollY: 0,
      categorySidebarExpanded: false,
    };
  },
  watch: {
    filteredProducts: 'detectFocusedCategory',
    '$route.params.languageCode'() {
      // todo: better fix
      // without this, category buttons become invisible after language change
      window.scrollBy({ top: 1 });
    },
    '$route.query.category_type': 'detectFocusedCategory',
    focusedCategoryId: 'scrollToFocusedCategoryButton',
    filteredCategories: 'scrollToFocusedCategoryButton',
    search: 'scrollToTop',
  },
  computed: {
    ...mapState(['restaurant']),
    ...mapGetters(['menu', 'availableCategoryTypes']),
    searchExpanded() {
      return this.searchFocused || this.search.length > 0;
    },
    scrollToTopButtonVisible() {
      return this.windowScrollY > 200;
    },
    isMenuSelectionButtonVisible() {
      if (this.restaurant.isWelcomePageEnabled) {
        return true;
      }

      return Boolean(this.menu?.isHomePageEnabled);
    },
    normalizedSearch() {
      return this.normalizeString(this.search);
    },
    filteredCategories() {
      if (!this.menu) return [];

      return this.menu.categories.filter((category) => {
        if (this.isCategoryNotMatchedByCategoryType(category)) {
          return false;
        }

        if (this.isCategoryExplicitlyMatchedBySearch(category)) {
          return true;
        }

        if (
          category.products.some((product) =>
            this.isProductExplicitlyMatchedBySearch(product),
          )
        ) {
          return true;
        }

        return false;
      });
    },
    bannerVideo() {
      if (this.$store.state.isEmbedMode) {
        return null;
      }

      return this.menu.bannerVideo;
    },
    // bannerUrl() {
    //   return this.menu.bannerUrl || this.restaurant.bannerUrl;
    // },
    banner() {
      if (this.$store.state.isEmbedMode) {
        return null;
      }

      return this.menu.banner || this.restaurant.banner;
    },
  },
  mounted() {
    this.detectFocusedCategory();
    document.addEventListener('scroll', this.onScroll);
  },
  unmounted() {
    document.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    isCategoryNotMatchedByCategoryType(category) {
      const categoryType = this.$route.query.category_type;

      if (!categoryType) return false;

      // safety measure
      if (
        !this.availableCategoryTypes.some(({ slug }) => slug === categoryType)
      ) {
        return false;
      }

      return category.type !== categoryType;
    },
    isCategoryExplicitlyMatchedBySearch(category) {
      if (!this.normalizedSearch) return true;

      return [...Object.values(category.name)].some((name) =>
        this.normalizeString(name).includes(this.normalizedSearch),
      );
    },
    isProductExplicitlyMatchedBySearch(product) {
      if (!this.normalizedSearch) return true;

      return [...Object.values(product.name)].some((name) =>
        this.normalizeString(name).includes(this.normalizedSearch),
      );
    },
    onScroll() {
      this.detectFocusedCategory();
      this.windowScrollY = window.scrollY;
    },
    getCategoryEl(categoryId) {
      const els = this.$refs[`category-${categoryId}`];

      return els && els.length > 0 ? els[0] : null;
    },
    getCategoryButtonEl(categoryId) {
      const els = this.$refs[`category-button-${categoryId}`];

      return els && els.length > 0 ? els[0] : null;
    },
    getCategoryButtonsEl() {
      const el = this.$refs['category-buttons'];

      return el ? el : null;
    },
    getHeaderNavigationContainerHeight() {
      return this.$refs.headerNavigationContainer.clientHeight;
    },
    getStickyBarHeight() {
      return this.$refs.stickyBar.clientHeight;
    },
    async detectFocusedCategory() {
      await this.$nextTick();

      const focusedCategory =
        [...this.filteredCategories].reverse().find((category) => {
          const categoryEl = this.getCategoryEl(category.id);

          if (!categoryEl) {
            return false;
          }

          return (
            categoryEl.getBoundingClientRect().top <=
            this.getStickyBarHeight() +
              this.getHeaderNavigationContainerHeight()
          );
        }) ?? this.filteredCategories[0];

      this.focusedCategoryId = focusedCategory ? focusedCategory.id : null;
    },
    async scrollToFocusedCategoryButton() {
      const categoryButtonEl = this.getCategoryButtonEl(this.focusedCategoryId);
      const categoryButtonsEl = this.getCategoryButtonsEl();

      // todo: refactor
      const dir = document.getElementsByTagName('html')[0].dir || 'ltr';

      if (categoryButtonEl && categoryButtonsEl) {
        categoryButtonsEl.scrollTo({
          left:
            dir === 'ltr'
              ? categoryButtonEl.offsetLeft
              : categoryButtonEl.offsetLeft -
                categoryButtonsEl.offsetWidth +
                categoryButtonEl.offsetWidth,
          behavior: 'smooth',
        });
      }
    },
    scrollToCategory(categoryId) {
      const categoryEl = this.getCategoryEl(categoryId);

      if (categoryEl) {
        window.scrollTo({
          top:
            categoryEl.getBoundingClientRect().top +
            window.scrollY -
            this.getStickyBarHeight() -
            this.getHeaderNavigationContainerHeight() +
            1,
          behavior: 'smooth',
        });
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
      });
    },
    normalizeString(str) {
      try {
        str = typeof str === 'string' ? str : '';
        str = str.toLowerCase();
        // remove some special chars
        str = str.replaceAll(/['"“”‘’ /]/g, '');
        // remove repeated chars
        str = str.replaceAll(/(.)\1+/g, (str) => str[0]);
      } catch {
        //
      }

      return str;
    },
  },
};
</script>

<style>
.scrollbar-none::-webkit-scrollbar {
  display: none;
}
.scrollbar-none {
  scrollbar-width: none;
}
</style>
