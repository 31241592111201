<template>
  <div class="flex flex-col space-y-3">
    <div v-if="showOpeningHours && restaurant.openingHours">
      <div class="flex items-center">
        <ClockBold
          class="w-5 flex-shrink-0 text-theme-primary ltr:mr-2 rtl:ml-2"
        />
        <div>{{ $t('Opening hours') }}</div>
      </div>
      <div class="whitespace-pre-wrap pl-1 text-sm ltr:ml-6 rtl:mr-6">
        {{ restaurant.openingHours }}
      </div>
    </div>
    <div
      v-if="showWifi && restaurant.slug === 'darabzeen'"
      class="flex items-center"
    >
      <WifiHighBold
        class="w-5 flex-shrink-0 text-theme-primary ltr:mr-2 rtl:ml-2"
      />
      DARABZEEN 00000000
    </div>
    <div v-if="showAddress && restaurant.address" class="flex items-center">
      <MapPinBold
        class="w-5 flex-shrink-0 text-theme-primary ltr:mr-2 rtl:ml-2"
      />
      <div>
        <a :href="restaurant.addressUrl" target="_blank" rel="nofollow">{{
          restaurant.address
        }}</a>
      </div>
    </div>
    <div
      v-if="showContactNumber && restaurant.contactNumber"
      class="flex items-center"
    >
      <PhoneBold
        class="w-5 flex-shrink-0 text-theme-primary ltr:mr-2 rtl:ml-2"
      />
      <a :href="`tel:${restaurant.contactNumber}`" rel="nofollow">
        {{ restaurant.contactNumber }}
      </a>
    </div>
    <div v-if="showEmail && restaurant.email" class="flex items-center">
      <AtBold class="w-5 flex-shrink-0 text-theme-primary ltr:mr-2 rtl:ml-2" />
      <a :href="`mailto:${restaurant.email}`" rel="nofollow">
        {{ restaurant.email }}
      </a>
    </div>
    <div v-if="showWebsite && restaurant.website" class="flex items-center">
      <GlobeBold
        class="w-5 flex-shrink-0 text-theme-primary ltr:mr-2 rtl:ml-2"
      />
      <a :href="restaurant.website" target="_blank" rel="nofollow">
        {{ beautifyUrl(restaurant.website) }}
      </a>
    </div>
    <div v-if="showInstagram && restaurant.instagram" class="flex items-center">
      <InstagramLogoBold
        class="w-5 flex-shrink-0 text-theme-primary ltr:mr-2 rtl:ml-2"
      />

      <a :href="restaurant.instagram" target="_blank" rel="nofollow">
        {{ beautifyUrl(restaurant.instagram) }}
      </a>
    </div>
    <div v-if="showTiktok && restaurant.tiktok" class="flex items-center">
      <TiktokLogoBold
        class="w-5 flex-shrink-0 text-theme-primary ltr:mr-2 rtl:ml-2"
      />

      <a :href="restaurant.tiktok" target="_blank" rel="nofollow">
        {{ beautifyUrl(restaurant.tiktok) }}
      </a>
    </div>
    <div v-if="showFacebook && restaurant.facebook" class="flex items-center">
      <FacebookLogoBold
        class="w-5 flex-shrink-0 text-theme-primary ltr:mr-2 rtl:ml-2"
      />

      <a :href="restaurant.facebook" target="_blank" rel="nofollow">
        {{ beautifyUrl(restaurant.facebook) }}
      </a>
    </div>
    <div v-if="showWhatsapp && restaurant.whatsapp" class="flex items-center">
      <WhatsappLogoBold
        class="w-5 flex-shrink-0 text-theme-primary ltr:mr-2 rtl:ml-2"
      />

      <a :href="restaurant.whatsapp" target="_blank" rel="nofollow">
        WhatsApp
      </a>
    </div>
    <div
      v-if="showGoogleReviewsUrl && restaurant.googleReviewsUrl"
      class="flex items-center"
    >
      <GoogleLogoBold
        class="w-5 flex-shrink-0 text-theme-primary ltr:mr-2 rtl:ml-2"
      />

      <a :href="restaurant.googleReviewsUrl" target="_blank" rel="nofollow">
        {{ $t('Leave a review on Google') }}
      </a>
    </div>
    <div
      v-if="showTripadvisorUrl && restaurant.tripadvisorUrl"
      class="flex items-center"
    >
      <TripAdvisorLogo
        class="w-5 flex-shrink-0 text-theme-primary ltr:mr-2 rtl:ml-2"
      />

      <a :href="restaurant.tripadvisorUrl" target="_blank" rel="nofollow">
        {{ $t('Leave a review on TripAdvisor') }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PhoneBold from '@/phosphoricons/PhoneBold.vue';
import AtBold from '@/phosphoricons/AtBold.vue';
import MapPinBold from '@/phosphoricons/MapPinBold.vue';
import GlobeBold from '@/phosphoricons/GlobeBold.vue';
import ClockBold from '@/phosphoricons/ClockBold.vue';
import WifiHighBold from '@/phosphoricons/WifiHighBold.vue';
import InstagramLogoBold from '@/phosphoricons/InstagramLogoBold.vue';
import TiktokLogoBold from '@/phosphoricons/TiktokLogoBold.vue';
import FacebookLogoBold from '@/phosphoricons/FacebookLogoBold.vue';
import WhatsappLogoBold from '@/phosphoricons/WhatsappLogoBold.vue';
import GoogleLogoBold from '@/phosphoricons/GoogleLogoBold.vue';
import TripAdvisorLogo from '@/icons/TripAdvisorLogo.vue';

export default {
  components: {
    TripAdvisorLogo,
    GoogleLogoBold,
    WhatsappLogoBold,
    FacebookLogoBold,
    TiktokLogoBold,
    InstagramLogoBold,
    WifiHighBold,
    ClockBold,
    GlobeBold,
    MapPinBold,
    AtBold,
    PhoneBold,
  },
  props: {
    showOpeningHours: {
      type: Boolean,
      default: false,
    },
    showWifi: {
      type: Boolean,
      default: false,
    },
    showContactNumber: {
      type: Boolean,
      default: false,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    showEmail: {
      type: Boolean,
      default: false,
    },
    showWebsite: {
      type: Boolean,
      default: false,
    },
    showInstagram: {
      type: Boolean,
      default: false,
    },
    showTiktok: {
      type: Boolean,
      default: false,
    },
    showFacebook: {
      type: Boolean,
      default: false,
    },
    showWhatsapp: {
      type: Boolean,
      default: false,
    },
    showGoogleReviewsUrl: {
      type: Boolean,
      default: false,
    },
    showTripadvisorUrl: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['restaurant']),
    ...mapGetters(['menu']),
  },
  methods: {
    beautifyUrl(url) {
      const urlObject = new URL(url);

      if (
        urlObject.hostname === 'instagram.com' &&
        urlObject.pathname.split.length >= 2
      ) {
        return `@${urlObject.pathname.split('/')[1]}`;
      }

      if (
        ['tiktok.com', 'www.tiktok.com'].includes(urlObject.hostname) &&
        urlObject.pathname.split.length >= 2
      ) {
        return urlObject.pathname.split('/')[1];
      }

      return `${urlObject.hostname}${
        urlObject.pathname !== '/' ? urlObject.pathname : ''
      }`;
    },
  },
};
</script>
