<template>
  <MainLayout title="Track your order">
    <div
      class="sticky top-0 z-10 bg-theme-background"
      style="padding-top: var(--safe-area-inset-top)"
    >
      <div class="flex items-center p-4">
        <ADeepCircleButton
          class="flex-shrink-0"
          @click="$router.push({ name: 'root' })"
        >
          <template #icon><ArrowLeftBold /></template>
        </ADeepCircleButton>
        <div class="flex-1 truncate px-4 text-center text-lg font-medium">
          {{ $t('Track your order') }}
        </div>
        <div class="h-10 w-10 flex-shrink-0"></div>
      </div>
    </div>
    <div v-if="order" class="mx-auto max-w-xl p-4">
      <div class="mt-4 text-center text-3xl font-bold">
        {{ $t('Order number') }} #{{ order.orderNumber }}
      </div>
      <div v-if="meta.phoneNumber" class="mt-2 text-center">
        {{
          $t('If you have any questions about your order, please call us at')
        }}
        <a :href="`tel:${meta.phoneNumber}`" class="underline">{{
          meta.phoneNumber
        }}</a
        >.
      </div>
      <div v-if="order.estimatedArrivalAt" class="mt-5 text-center">
        <span class="font-medium">{{ $t('Estimated arrival') }}:</span><br />
        {{ $formatDateTime(order.estimatedArrivalAt, 'MMM d, hh:mm a') }}
      </div>
      <div class="mt-10">
        <AVerticalStep
          v-for="(historyItem, historyItemIndex) in historyItems"
          :key="historyItemIndex"
          :type="historyItem.type"
          :last="historyItemIndex === historyItems.length - 1"
        >
          <template #title> {{ historyItem.title }} </template>
          <template #description> {{ historyItem.description }} </template>
        </AVerticalStep>
      </div>
      <div class="mt-8">
        <div
          v-for="(displayField, displayFieldIndex) in order.displayFields"
          :key="displayFieldIndex"
        >
          <strong>{{ displayField.name }}: </strong>
          {{ displayField.value }}
        </div>

        <div class="my-3">
          <div class="border-t border-theme-text/20" />
        </div>

        <div>
          <div
            v-for="(orderItem, orderItemIndex) in order.orderItems"
            :key="orderItemIndex"
            class="mb-4 last:mb-0"
          >
            <p>
              <strong>{{ orderItem.quantity }}x</strong>
              {{ $getTranslation(orderItem.productName) }}
              <template v-if="$getTranslation(orderItem.productVariantName)">
                - {{ $getTranslation(orderItem.productVariantName) }}
              </template>
              <template v-if="orderItem.modifiers.length > 0">
                ({{
                  orderItem.modifiers
                    .map(({ modifierName }) => $getTranslation(modifierName))
                    .join(', ')
                }})
              </template>
            </p>

            <p v-if="orderItem.price > 0">
              {{
                $formatPrice(orderItem.price * orderItem.quantity, {
                  currencyCode: order.currencyCode,
                })
              }}
            </p>
          </div>
        </div>

        <div class="my-3">
          <div class="border-t border-theme-text/20" />
        </div>

        <div v-if="order.totalPrice > 0">
          <strong>{{ $t('Total amount') }}:</strong>
          {{
            $formatPrice(order.totalPrice, { currencyCode: order.currencyCode })
          }}
        </div>
      </div>

      <div class="mt-12 mb-8 flex justify-center">
        <RouterLink :to="{ name: 'root' }" class="menu-button-primary w-max">
          {{ $t('Back to menu') }}
        </RouterLink>
      </div>
    </div>
    <div v-else-if="!loaded" class="mt-10 text-center text-xl">
      {{ $t('Loading') }}...
    </div>
    <div v-else class="mt-10 text-center text-xl">
      {{ $t('Order not found') }}...
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from '@menu/Shared/MainLayout.vue';
import ADeepCircleButton from '@menu/Shared/ADeepCircleButton.vue';
import axios from 'axios';
import AVerticalStep from '@menu/Shared/AVerticalStep.vue';
import ArrowLeftBold from '@/phosphoricons/ArrowLeftBold.vue';

export default {
  components: {
    ArrowLeftBold,
    AVerticalStep,
    ADeepCircleButton,
    MainLayout,
  },
  data() {
    return {
      loaded: false,
      order: null,
      meta: null,
    };
  },
  computed: {
    historyItems() {
      if (!this.order) {
        return [];
      }

      const items = [];

      const addItem = ({ title, description, type = 'neutral', current }) => {
        items.push({
          title,
          description,
          type,
        });

        if (current) {
          items.forEach((item) => {
            if (item.type === 'neutral') {
              item.type = 'success';
            }
          });
        }
      };

      addItem({
        title: this.$t('Order placed'),
        description: this.$t('Your order has been placed.'),
        current: this.order.status === 'NEW',
      });

      if (this.order.status === 'REJECTED') {
        addItem({
          title: this.$t('Rejected'),
          description: this.$t('Reason') + ': ' + this.order.rejectionReason,
          current: true,
          type: 'error',
        });
      } else {
        addItem({
          title: this.$t('Preparing'),
          description: this.$t('Your order is being prepared.'),
          current: this.order.status === 'ACCEPTED',
        });

        if (this.order.type === 'PICKUP') {
          addItem({
            title: this.$t('Ready for pickup'),
            description: this.$t('Your order is ready for pickup.'),
            current: this.order.status === 'READY_FOR_PICKUP',
          });
        }

        if (this.order.type === 'DELIVERY') {
          addItem({
            title: this.$t('Out for delivery'),
            description: this.$t('Your order is out for delivery.'),
            current: this.order.status === 'OUT_FOR_DELIVERY',
          });
        }

        addItem({
          title: this.$t('Completed'),
          description: this.$t('Your order has been completed.'),
          current: this.order.status === 'COMPLETED',
        });
      }

      return items;
    },
  },
  mounted() {
    this.loadOrder();

    this.intervalId = setInterval(() => {
      if (this.order.status !== 'COMPLETED') {
        this.loadOrder();
      }
    }, 10000);
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
  methods: {
    loadOrder() {
      axios
        .get('/api/orders/' + this.$route.params.orderUuid)
        .then((response) => {
          this.order = response.data.data;
          this.meta = response.data.meta;
          document.title = `Order #${this.order.orderNumber} - ${this.$store.state.restaurant.name}`;
        })
        .finally(() => {
          this.loaded = true;
        });
    },
  },
};
</script>
