<template>
  <Transition
    enter-active-class="ease-in duration-200"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-out duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="product"
      ref="scrollableContent"
      v-prevent-body-scroll
      class="fixed top-0 left-0 right-0 z-50 h-screen transform overflow-y-scroll bg-theme-background/50 backdrop-blur"
      @scroll="onScroll"
    >
      <div
        class="mx-auto flex h-full min-h-screen w-full flex-col items-center px-4"
        style="min-height: calc(100vh + 2px)"
      >
        <div class="h-10 w-full shrink-0"></div>

        <div
          v-click-outside="onClickOutsideContent"
          class="w-[550px] max-w-full shrink-0 rounded-xl bg-theme-background shadow-lg portrait:w-[650px]"
        >
          <div
            v-if="shouldDisplayMedia"
            class="flex w-full shrink-0 items-center overflow-hidden"
          >
            <div
              class="relative z-40 w-full"
              :style="{
                paddingBottom: `${
                  product.image && product.image.height && product.image.width
                    ? (product.image.height / product.image.width) * 100
                    : 100
                }%`,
              }"
            >
              <div
                style="transform: translate3d(0, 0, 0)"
                class="absolute top-7 right-7 z-20"
              >
                <ADeepCircleButton shadow @click="hide">
                  <template #icon><XBold /></template>
                </ADeepCircleButton>
              </div>

              <video
                v-if="product.video && $store.state.onLine"
                class="absolute inset-0 h-full w-full rounded-t-xl bg-center object-cover"
                autoplay
                controls
                muted
                playsinline
                loop
                :poster="product.image?.url"
                width="100%"
                @play="
                  window.setTimeout(() => ($event.target.controls = false), 50)
                "
                @click="$event.target.controls = true"
                @mouseenter="$event.target.controls = true"
              >
                <source :src="product.video.url" type="video/mp4" />
              </video>

              <AImage
                v-else-if="product.image"
                :src="product.image.url"
                class="absolute inset-0 h-full w-full rounded-t-xl bg-center object-cover"
                :alt="$getTranslation(product.name)"
              />
            </div>
          </div>

          <div class="w-full px-6 py-5">
            <div class="flex justify-between">
              <div class="flex min-w-0 items-center">
                <div class="min-w-0 break-words text-xl font-semibold">
                  {{ $getTranslation(product.name) }}
                </div>
                <div class="h-6"></div>
              </div>
              <div class="flex shrink-0 ltr:pl-6 rtl:pr-6">
                <template v-if="restaurant.isWishlistEnabled">
                  <ADeepCircleButton
                    v-if="!isFavorite"
                    class="ltr:ml-3 rtl:mr-3"
                    @click="addToFavorites"
                  >
                    <template #icon><HeartBold /></template>
                  </ADeepCircleButton>
                  <ADeepCircleButton
                    v-else
                    class="ltr:ml-3 rtl:mr-3"
                    @click="removeFromFavorites"
                  >
                    <template #icon><HeartFill /></template>
                  </ADeepCircleButton>
                </template>

                <ADeepCircleButton
                  v-if="!shouldDisplayMedia"
                  class="ltr:ml-3 rtl:mr-3"
                  @click="hide"
                >
                  <template #icon><XBold /></template>
                </ADeepCircleButton>
              </div>
            </div>

            <div
              v-if="product.ingredientWarnings.length > 0"
              class="mt-1 text-sm italic text-theme-text/80"
            >
              {{
                product.ingredientWarnings
                  .map(
                    (w) =>
                      `${$getTranslation(w.title, { fallbackToEn: true })}`,
                  )
                  .join(', ')
              }}
            </div>

            <ProductBadges class="mt-2 empty:hidden" :product="product" />

            <div
              class="mt-3 whitespace-pre-line break-words font-secondary text-theme-text/90 [&_a]:underline"
            >
              <div
                class="[&>p:empty]:h-[1em] [&>p]:mb-2"
                v-html="$getTranslation(product.description)"
              />
              <div
                v-if="menu && menu.globalProductNote"
                class="[&>p:empty]:h-[1em] [&>p]:mb-2"
                v-html="$getTranslation(menu.globalProductNote)"
              />
            </div>

            <div class="mt-5">
              <div class="divide-y divide-theme-text/20">
                <ProductRadioOption
                  v-for="(variant, variantIndex) in product.variants"
                  :key="variantIndex"
                  :name="$getTranslation(variant.name)"
                  :price="variant.price"
                  :selected="selectedVariantId === variant.id"
                  :readonly="
                    !$store.state.isOrderMode || product.variants.length === 1
                  "
                  @click="selectedVariantId = variant.id"
                />
              </div>
            </div>

            <div>
              <div
                v-for="modifierSet in modifierSets"
                :key="modifierSet.id"
                class="mt-6"
              >
                <div class="mb-2 flex flex-wrap items-center justify-between">
                  <div class="mr-4 text-lg font-semibold">
                    {{ $getTranslation(modifierSet.name) }}
                  </div>

                  <div class="opacity-70">
                    {{ selectionRequirementsLabelForModifierSet(modifierSet) }}
                  </div>
                </div>

                <div class="divide-y divide-theme-text/20">
                  <ProductRadioOption
                    v-for="modifier in modifierSet.modifiers"
                    :key="modifier.id"
                    :name="$getTranslation(modifier.name)"
                    :price="modifier.price"
                    :checkbox="
                      modifierSet.minSelection !== 1 ||
                      modifierSet.maxSelection !== 1
                    "
                    price-plus
                    :selected="
                      Boolean(
                        selectedModifierIds[modifierSet.id]?.includes(
                          modifier.id,
                        ),
                      )
                    "
                    :readonly="!$store.state.isOrderMode"
                    @click="selectModifier(modifierSet, modifier)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="$store.state.isOrderMode && orderingConfiguration"
            class="sticky bottom-0 z-40 mt-4 flex items-center rounded-b-xl bg-theme-background px-4 pt-4 pb-4"
            style="box-shadow: 0 -1px 24px #0000001f"
          >
            <div class="flex justify-center">
              <QuantityPicker v-model="quantity" :min="1" />
            </div>
            <button
              type="button"
              class="menu-button-primary h-12 w-full !text-sm ltr:ml-3 rtl:mr-3 md:!text-base"
              :disabled="
                pendingAddToCartTotalPrice <= 0 &&
                !orderingConfiguration.canOrderFreeItems
              "
              @click="addToCart"
            >
              {{ $t('Add to cart') }}
              <span v-if="pendingAddToCartTotalPrice > 0"
                >({{ $formatPrice(pendingAddToCartTotalPrice) }})</span
              >
            </button>
          </div>
        </div>
        <div class="flex justify-center py-4">
          <button
            type="button"
            class="flex items-center rounded-full border border-theme-text px-2 py-0.5 focus:outline-none"
            @click="hide"
          >
            <XBold class="mx-px h-4 w-4" />
            <span class="mx-px text-sm">{{ $t('Close') }}</span>
          </button>
        </div>
        <div class="h-32 w-full shrink-0"></div>
      </div>
    </div>
  </Transition>
</template>

<script>
import ADeepCircleButton from '@menu/Shared/ADeepCircleButton.vue';
import ProductBadges from '@menu/Shared/ProductBadges.vue';
import AImage from '@menu/Shared/AImage.vue';
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';
import QuantityPicker from '@menu/Shared/QuantityPicker.vue';
import ProductRadioOption from '@menu/Shared/ProductRadioOption.vue';
import { isEqual } from 'lodash';
import HeartBold from '@/phosphoricons/HeartBold.vue';
import HeartFill from '@/phosphoricons/HeartFill.vue';
import XBold from '@/phosphoricons/XBold.vue';

export default {
  components: {
    XBold,
    HeartFill,
    HeartBold,
    ProductRadioOption,
    QuantityPicker,
    AImage,
    ProductBadges,
    ADeepCircleButton,
  },
  emits: ['hide'],
  data() {
    return {
      quantity: 1,
      selectedVariantId: null,
      selectedModifierIds: {},
    };
  },
  computed: {
    ...mapState(['restaurant']),
    ...mapGetters(['menu']),
    product() {
      return this.$store.state.previewingProduct;
    },
    modifierSets() {
      if (!this.menu) {
        // sometimes `this.menu` is null
        return [];
      }

      return this.product.modifierSetIds
        .map((modifierSetId) =>
          this.menu.modifierSets.find(({ id }) => id === modifierSetId),
        )
        .filter(Boolean);
    },
    selectedModifiers() {
      let selectedModifiers = [];

      this.modifierSets.forEach((modifierSet) => {
        modifierSet.modifiers.forEach((modifier) => {
          if (this.selectedModifierIds[modifierSet.id]?.includes(modifier.id)) {
            selectedModifiers.push(modifier);
          }
        });
      });

      return selectedModifiers;
    },
    isFavorite() {
      return this.$store.state.favoriteProductIds.includes(this.product.id);
    },
    shouldDisplayMedia() {
      if (this.product.image) {
        return true;
      }
      if (this.product.video && this.$store.state.onLine) {
        return true;
      }

      return false;
    },
    pendingAddToCartTotalPrice() {
      let price = 0;

      const variant = this.product.variants.find(
        ({ id }) => id === this.selectedVariantId,
      );

      if (variant) {
        price += variant.price;
      }

      this.selectedModifiers.forEach((modifier) => {
        if (modifier.price) {
          price += modifier.price;
        }
      });

      return price * this.quantity;
    },
    orderingConfiguration() {
      return this.$store.state.restaurant.orderingConfiguration;
    },
  },
  watch: {
    product: {
      handler(product) {
        if (product) {
          this.quantity = 1;
          this.selectedVariantId =
            this.product.variants.length > 0
              ? this.product.variants[0].id
              : null;
          this.selectedModifierIds = this.modifierSets.reduce(
            (acc, modifierSet) => {
              acc[modifierSet.id] = [];

              // if (modifierSet.modifiers.length > 0) {
              //   acc[modifierSet.id] = [modifierSet.modifiers[0].id];
              // }

              return acc;
            },
            {},
          );

          this.trackProductView(product.id);
        }
      },
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown);
  },
  unmounted() {
    window.removeEventListener('keydown', this.onKeydown);
  },
  methods: {
    onKeydown(event) {
      if (event.key === 'Escape') {
        this.hide();
      }
    },
    addToFavorites() {
      this.$store.commit('addToFavorites', { productId: this.product.id });
    },
    removeFromFavorites() {
      this.$store.commit('removeFromFavorites', { productId: this.product.id });
    },
    onScroll(event) {
      // Hide popup on overscroll.
      const overscrollTop = -event.target.scrollTop;
      const overscrollBottom = -(
        event.target.scrollHeight -
        event.target.offsetHeight -
        event.target.scrollTop
      );
      const overscrollThreshold = 130;
      if (
        overscrollTop > overscrollThreshold ||
        overscrollBottom > overscrollThreshold
      ) {
        this.hide();
        return;
      }
    },
    onClickOutsideContent() {
      this.hide();
    },
    hide() {
      this.$emit('hide');
    },
    trackProductView(productId) {
      axios
        .post('/api/product-views', {
          restaurantId: this.$store.state.restaurant.id,
          productId,
        })
        .finally(() => {
          //
        });
    },
    addToCart() {
      if (this.product.variants.length === 0) {
        throw new Error('There are no variants.'); // todo
      }

      if (!this.checkMinSelectionRequirements()) {
        return;
      }

      const modifierIds = this.selectedModifiers.map(({ id }) => id);

      const currentQuantity =
        this.$store.state.cartItemsIds.find(
          (cartItemIds) =>
            cartItemIds.productId === this.product.id &&
            cartItemIds.variantId === this.selectedVariantId &&
            isEqual(cartItemIds.modifierIds, modifierIds),
        )?.quantity ?? 0;

      this.$store.commit('setCartItemQuantity', {
        productId: this.product.id,
        variantId: this.selectedVariantId,
        modifierIds,
        quantity: currentQuantity + this.quantity,
      });

      this.hide();
    },
    checkMinSelectionRequirements() {
      let isValid = true;

      this.modifierSets.forEach((modifierSet) => {
        if (
          modifierSet.minSelection !== null &&
          modifierSet.minSelection >
            this.selectedModifierIds[modifierSet.id].length
        ) {
          this.$toast.error(
            `Min selection of ${modifierSet.minSelection} is required for "${this.$getTranslation(modifierSet.name)}"`,
          );
          isValid = false;
        }
      });

      return isValid;
    },
    selectionRequirementsLabelForModifierSet(modifierSet) {
      if (modifierSet.minSelection === 1 && modifierSet.maxSelection === 1) {
        return this.$t('Required');
      }

      const parts = [];

      if (modifierSet.minSelection !== null && modifierSet.minSelection >= 1) {
        parts.push(`Min: ${modifierSet.minSelection}`);
      } else {
        parts.push(this.$t('Optional'));
      }

      if (modifierSet.maxSelection !== null) {
        parts.push(`Max: ${modifierSet.maxSelection}`);
      }

      return parts.join(', ');
    },
    selectModifier(modifierSet, modifier) {
      if (modifierSet.minSelection === 1 && modifierSet.maxSelection === 1) {
        this.selectedModifierIds[modifierSet.id] = [modifier.id];
      } else {
        const index = this.selectedModifierIds[modifierSet.id].indexOf(
          modifier.id,
        );

        if (index !== -1) {
          this.selectedModifierIds[modifierSet.id].splice(index, 1);
        } else if (
          modifierSet.maxSelection !== null &&
          modifierSet.maxSelection <=
            this.selectedModifierIds[modifierSet.id].length
        ) {
          this.$toast.error(
            `Max selection of ${modifierSet.maxSelection} is reached for "${this.$getTranslation(modifierSet.name)}"`,
          );
        } else {
          this.selectedModifierIds[modifierSet.id].push(modifier.id);
        }
      }
    },
  },
};
</script>
